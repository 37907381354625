import React from 'react';

function Notice() {
  return (
    <div className="badge bg-info d-block text-center text-bold bd-announcement p-1">
      TESTING APP
    </div>
  );
}

export default Notice;