import React from 'react'
import Header from './Header'
import Footer from './Footer'
import PricingBody from './PricingBody'

const Pricing = () => {
  return (
    
  <>
  <Header/>
  <PricingBody/>
  <Footer/>
  </>

  )
}

export default Pricing