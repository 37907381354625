import React from 'react'
import Header from './Header'
import Footer from './Footer'
import HomeBody from './HomeBody'

const Home = () => {
  return (
    
   <>
   <Header/>
   <HomeBody/>
   <Footer/>
   </>

  )
}

export default Home